html, body, #map {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; border-radius: 4px;box-shadow: 2px 2px rgba(0,0,0,.175);padding: 5px;}
.autocomplete-suggestion { padding: 3px 20px; white-space: nowrap; overflow: hidden; cursor:pointer; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }
.bootbox .modal-header{display: block;}
.modal.modal-left .modal-dialog {
    margin-left: 3rem!important;
    margin-top: 5rem!important;
    margin-right: auto;
}
@media (max-width: 576px) {
    .modal.modal-left .modal-dialog {
        margin-left:auto!important;;
        margin-top:auto!important;
    }
}
.btn,.card-body{
    white-space: nowrap
}